import React from 'react';
import SkillsEducation from '../components/SkillsEducation';

const SkillsEducationPage: React.FC = () => {
  return (
    <SkillsEducation />
  );
};

export default SkillsEducationPage;
